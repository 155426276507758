import React, { useRef } from "react"

// import { isEmpty } from "lodash"

import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Form from "./elements/Form"
import LanguageSelect from "./elements/LanguageSelect"
import PhoneInput from "./elements/PhoneInput"
import Recaptcha from "./elements/Recaptcha"
import TermsConditions from "./elements/TermsConditions"
import TextInput from "./elements/TextInput"
import { contactSchema, showNumberSchema } from "./schemas"
import {
  formTracking,
  generateUuid,
  getBitrixData,
  isProduction,
  postFormData,
  sendMail,
  useBlock,
} from "./utils"
import useEmailContent from "@hooks/dataHooks/useEmailContent"
import { useGlobalStore } from "@stores/globalStore"
const ShowNumberForm = () => {
  const recaptchaRef = useRef()
  const data = useEmailContent()
  const { ipAddress } = useGlobalStore()
  const formConfig = FORM_CONFIG.showNumber
  let emailContentTemp = data?.filter((item) => item.choose_template ===  formConfig?.emailTempUser);
  const emailContent = emailContentTemp[0]?.content?.data?.content;
  const onSubmit = async (data) => {
    const ipList = await useBlock();
    const isBlocked = ipList?.ip?.some(item => item?.add_ip_address === ipAddress);
    const isBlocked1 = ipList?.email?.some(item => item?.email === data.email);
    const token = await recaptchaRef.current?.executeAsync()

    if (token) {
      const unique_id = generateUuid()
      const postData = {
        first_name: data.name,
        url: window.location.href,
        unique_id: unique_id,
        "g-recaptcha-response": token,
        ...data,
        ...formConfig,
        extra: { formConfig, data },
      }

      const bitrixData = getBitrixData(postData)

      const formData = new FormData()

      formData.append("data", JSON.stringify(bitrixData))

      recaptchaRef.current?.reset()
      if(isBlocked || isBlocked1){
        return;
      }
      else {
      postFormData(formData).then(async () => {
        await sendMail(bitrixData)
      })
      formTracking({
        unique_id: unique_id,
        eventTracking: formConfig.eventTracking,
        formName: formConfig.form_name,
      })
    }
  }
  

  }
  return (
    <Form
      className="showno-form"
      validationSchema={showNumberSchema}
      onSubmit={onSubmit}
      successMsg={SUCCESS_MSG}
    >
      <div className="form-grid">
        <PhoneInput name="telephone" />
        <div className="d-none"><TextInput name="emailContent" type="hidden" value={emailContent} /></div>
      </div>
      <div className="form-bottom">
        <button className="button button-orange" type="submit">
          <span>Submit Details</span>
        </button>
        <TermsConditions />
      </div>
      <Recaptcha recaptchaRef={recaptchaRef} />
    </Form>
  )
}

export default ShowNumberForm
